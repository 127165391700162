.product-img {
    height: 120px;
    width: 140px;
}

.product-container {
    height: 200px;
    width: 200px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
}

.Products-container {
    display: flex;
    justify-content: center;
    align-items: center;
}