.header{
    height: 100px;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    background-color: black;
}

.links {
    height: 35px;
}

.header-img {
    border: 2px solid white;
    height: 100%;
    width: 90px;
}

.header-img:hover {
    height: 40px;
    width: 95px;
}

