.login-container {
    height: 275px;
    width: 350px;
    border-radius: 5%;
    color: black;
    background-color:white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.buttons {
    height: 50px;
    width: 100px;  
}

.buttons:hover {
    height: 55px;
    width: 105px;
}

#register {
    width: 160px;
}

#register:hover {
    width: 165px;
}

.input {
    height: 20px;
    border: 2px solid rgb(211, 211, 211);
    border-radius: 10%;
}